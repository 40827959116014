@media (max-width: 991px) {
  .min-nav {
    background: #fff;
    right: 0;
    top: 75px;
    width: 300px;
    z-index: 11;
    padding: 12px;
    position: absolute;
    border-radius: 10px;
  }

  .navbar-light .navbar-nav .nav-link {
    color: #000 !important;
  }

  .banner_caption {
    top: -15%;
  }

  .nav_me img {
    width: 350px;
    display: block;
  }

  .logo_img img {
    display: none;
  }

  .social_feedb {
    width: 100%;
  }

  .h_500 {
    height: 300px;
  }

  .new_update {
    position: inherit;
    width: 100%;
  }

  .news_card {
    margin: 20px 15px 20px 10px;
  }

  .new_update h3 {
    color: #000;
    margin-bottom: auto;
  }

  .pos_contcenter {
    position: absolute;
    width: 100%;
    bottom: 15%;
    left: 50%;
    transform: translate(-50%, -15%);
    display: flex;
    align-items: center;
    color: #fff;
    z-index: 1;
  }

  .banner_content {
    position: absolute;
    width: 65%;
    top: 35%;
    left: 20%;
    transform: translate(-20%, -50%);
    display: flex;
    align-items: center;
    color: #fff;
    z-index: 2;
  }

  .social_feedb iframe {
    width: 100%;
    height: auto;
  }

  .know_login {
    padding: 5% 5% 15%;
  }

  .social_feedb iframe {
    height: auto !important;
  }
}

@media (max-width: 767px) {

  /* .social_feedb{
        width: 100%;     
        display: flex;
        justify-content: center; 
        left: 0;
    } */
  .banner_content h1 {
    font-size: 40px;
  }

  .banner_caption {
    top: -20%;
  }

  .table_section table {
    width: 500px;
  }

  /* .table_section table td{
        text-wrap: nowrap;
    } */
  .rte_provide {
    padding: 20px;
  }

  .mis_img {
    width: 90%;
    margin: auto;
    margin-top: 30px;
    flex-wrap: wrap;
  }

  .mission_left {
    width: 100%;
    text-align: center;
  }

  .mission_left img {
    height: auto;
    width: 100%;
    border-radius: 20px;
  }

  .mission_leftcon {
    position: inherit;
    top: auto;
    left: auto;
    width: 100%;
    margin-top: 30px;
    padding: 20px;
    border-radius: 20px;
    background: #0140a280;
    z-index: 1;
  }

  .mission_right {
    position: relative;
    margin-top: 30px;
    text-align: center;
  }

  .mission_right img {
    height: auto;
    width: 100%;
    border-radius: 20px;
  }

  .mission_righcon {
    position: inherit;
    top: auto;
    right: auto;
    width: 100%;
    margin-top: 30px;
    padding: 20px;
    border-radius: 20px;
    background: #0140a280;
  }

  .reach_section {
    padding: 15px;
  }

  .mis_1,
  .mis_2,
  .mis_3 {
    width: 100%;
    height: auto;
  }
  .th_section{
    background-position: center;
  }
  .th_cont h3{
    font-size: 25px;
  }
}

@media (max-width: 567px) {
  h1 {
    font-size: 35px !important;
  }

  h2 {
    font-size: 30px !important;
  }

  h3 {
    font-size: 25px !important;
  }

  .banner_caption {
    top: -15%;
  }

  .banner_content h1 {
    font-size: 25px !important;
  }

  .banner_content p {
    font-size: 14px !important;
    line-height: 25px;
  }

  .nav_me img {
    width: 220px;
  }

  .achieve_img {
    height: 300px;
  }

  .page_banner {
    height: 40vh;
  }

  .count_card {
    text-align: center;
  }

  .feed_img {
    height: 100px;
  }

  .new_update {
    width: 90%;
  }

  .count_card p {
    text-align: center;
  }

  .scert_table {
    padding: 60px 30px;
  }

  .captcha_sec {
    width: 200px;
  }
  .th_cont h3{
    font-size: 16px;
  }
}

@media screen and (max-width: 900px) {
  .timeline ul li div {
    width: 250px;
  }

  .timeline ul li:nth-child(even) div {
    left: -289px;
    /*250+45-6*/
  }
}

@media screen and (max-width: 600px) {
  .timeline ul li {
    margin-left: 20px;
  }

  .timeline ul li div {
    width: calc(100vw - 91px);
  }

  .timeline ul li:nth-child(even) div {
    left: 45px;
  }

  .timeline ul li:nth-child(even) div::before {
    left: -15px;
    border-width: 8px 16px 8px 0;
    border-color: transparent #ffce00 transparent transparent;
  }
}

@media (max-width: 567px) {
  .lead_card {
    width: 100%;
  }

  .lead_card h6 {
    font-size: 8px;
  }

  .fms_card {
    padding: 50px 20px;
  }

  .w_500 {
    width: 100%;
  }

  .w_700P {
    width: 100%;
  }
}