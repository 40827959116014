@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Readex+Pro:wght@160..700&display=swap");

* {
  padding: 0;
  margin: 0;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100% !important;
  overflow-x: hidden;
}

.main {
  position: relative;
}

/* Common style starts */

.txt_blk {
  color: #000 !important;
}

.txt_wh {
  color: #fff !important;
}

.txt_darkbl {
  color: #0140a2 !important;
}

a {
  text-decoration: none !important;
}

ul {
  list-style: none !important;
  padding-left: 0 !important;
  margin-bottom: 0 !important;
}

.btn_new {
  display: inline-block;
}

.btn_new a {
  background: linear-gradient(
    83deg,
    rgba(94, 148, 230, 1) 0%,
    rgba(111, 161, 233, 1) 50%,
    rgba(5, 80, 213, 1) 100%
  );
  color: #fff;
  border-radius: 100px !important;
  border: none;
  padding: 15px 30px;
  font-weight: 700;
  font-size: 16px;
  transition: all 0.8s ease;
}

.btn_new a:hover {
  background: linear-gradient(
    83deg,
    rgb(66, 121, 202) 0%,
    rgb(59, 106, 172) 50%,
    rgb(0, 57, 155) 100%
  );
  transition: all 0.8s ease;
}

.btn_new button {
  background: linear-gradient(
    83deg,
    rgba(94, 148, 230, 1) 0%,
    rgba(111, 161, 233, 1) 50%,
    rgba(5, 80, 213, 1) 100%
  );
  color: #fff;
  border-radius: 100px !important;
  border: none;
  padding: 15px 30px;
  font-weight: 700;
  font-size: 16px;
}

.mar_70 {
  margin: 70px 0px;
}

.mar_100 {
  margin: 100px 0px;
}

.mar_50 {
  margin: 40px 0px;
}

.mt_30 {
  margin-top: 30px;
}

.mt_50 {
  margin-top: 50px;
}

.w_500 {
  width: 500px;
  margin: auto;
}

.w_700 {
  width: 700px;
  margin: auto;
}

p {
  color: #000;
  line-height: 30px;
  font-weight: 500;
  font-size: 16px;
  text-align: justify;
}

.d-flex-jc-ac {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.d-flex-jc {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.text_just {
  text-align: justify;
}

.mt_10 {
  margin-top: 10px;
}

.mt_15 {
  margin-top: 15px;
}

.mt_20 {
  margin-top: 20px;
}

.mt_25 {
  margin-top: 25px;
}

.mt_55 {
  margin-top: 55px;
}

.mt_70 {
  margin-top: 70px;
}

.mb_0 {
  margin-bottom: 0px;
}

.mb_5 {
  margin-bottom: 5px;
}

.mb_10 {
  margin-bottom: 10px;
}

.mb_15 {
  margin-bottom: 15px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_25 {
  margin-bottom: 25px;
}

.mb_30 {
  margin-bottom: 30px;
}

.mx_10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mb_55 {
  margin-bottom: 55px;
}

.w-90 {
  width: 90%;
  margin: auto;
}

.pos_rel {
  position: relative;
  z-index: 1;
}

/* Common style ends */

/* navbar starts */

.nav_bar {
  position: sticky !important;
  top: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
}
.logo_img img {
  width: 350px;
}

.lead_ic {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
  margin: 5px auto;
}

.lead_ic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.lead_card {
  width: 150px;
  text-align: center;
}

.lead_card h6 {
  font-size: 12px;
  font-weight: 800;
}

.top_section {
  padding: 20px 0px 0px;
}

.bg_gra {
  padding: 15px 0px !important;
  background: linear-gradient(
    83deg,
    rgba(94, 148, 230, 1) 0%,
    rgba(111, 161, 233, 1) 50%,
    rgba(5, 80, 213, 1) 100%
  );
}

.old_site {
  text-decoration: underline !important;
  font-size: 12px;
  margin-right: 15px;
}

.nav_me img {
  width: 350px;
  display: none;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.navbar-nav .nav-item {
  margin: 0px 5px;
  cursor: pointer;
}

.navbar-light .navbar-toggler {
  background-color: #fff;
  color: #00b6f0 !important;
  padding: 10px 13px;
}

.dr_hv {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600 !important;
  cursor: pointer;
}

.dr_hv:hover {
  background: linear-gradient(
    83deg,
    rgba(94, 148, 230, 1) 0%,
    rgba(111, 161, 233, 1) 50%,
    rgba(5, 80, 213, 1) 100%
  );
  color: #fff !important;
}

.logo_xl {
  display: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none !important;
}

/* navbar ends */

/* home banner starts */

.hom_banner {
  position: relative;
}

.banner_img {
  position: relative;
  height: 100vh;
}

.banner_overlay::before {
  position: absolute;
  display: none !important;
  opacity: 0;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0000003b;
}

.banner_img img,
.banner_img video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pos_contcenter {
  position: absolute;
  width: 100%;
  bottom: 25%;
  left: 50%;
  transform: translate(-50%, 20%);
  display: flex;
  align-items: center;
  color: #fff;
  z-index: 1;
}

.banner_content {
  position: absolute;
  width: 50%;
  top: 50%;
  left: 10%;
  transform: translate(-10%, -50%);
  display: flex;
  align-items: center;
  color: #fff;
  z-index: 2;
}

.banner_content h1 {
  font-weight: 800;
  font-size: 50px;
  margin: 15px 0px;
  text-shadow: 7px 7px 12px #000;
}

.banner_content p {
  color: #fff;
  text-shadow: 7px 7px 12px #000;
}

.carousel-control-next,
.carousel-control-prev {
  width: 5% !important;
  top: 40% !important;
  height: 10% !important;
}

.social_feedb {
  margin: 0 auto;
  background: #fff;
  padding: 15px;
  border-radius: 20px;
  position: relative;
}

/* social media feed */
.social_feedb .clse_btn {
  position: absolute;
  top: -10px;
  right: -10px;
  border: 0;
  background: #0140a2;
  width: 40px;
  height: 40px;
  z-index: 1;
  color: #fff;
  border-radius: 100%;
}

.social_show {
  position: fixed;
  right: 0;
  bottom: 3%;
  z-index: 111;
}

.social_show .social_btn {
  font-size: 15px;
  background: linear-gradient(317deg, #298000, #00c644);
  color: #fff !important;
  display: inherit;
  padding: 5px 10px;
  position: absolute;
  width: auto;
  bottom: 100px;
  right: 0;
  z-index: 99;
  text-align: center;
  border-radius: 10px 0px 0px 10px;
  font-weight: 600;
}

/* social */
iframe ._5pcb {
  width: 100% !important;
}

iframe ._5tmf {
  width: 100% !important;
}

iframe ._50f3 {
  width: 100% !important;
}

.soc_head h4 {
  font-size: 15px;
  background: linear-gradient(317deg, #298000, #00c644);
  color: #fff !important;
  display: inherit;
  padding: 5px 10px;
  position: absolute;
  width: auto;
  bottom: 130px;
  right: 0;
  z-index: 99;
  text-align: center;
  border-radius: 10px 0px 0px 10px;
  font-weight: 600;
}

.soc_head a {
  font-size: 12px;
  font-weight: 400;
  color: #000;
}

.feed_card {
  background: #f6f6f6;
  padding: 8px;
  border-radius: 6px;
  margin-top: 10px;
}

.feed_card p {
  font-size: 14px;
  font-weight: 500;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
}

.txt_red {
  color: #ea3a3d;
}

.txt_bl {
  color: #217efd;
}

.feed_ban {
  margin-bottom: 15px;
}

.feed_ban img {
  width: 100%;
  border-radius: 20px;
}

.feed_card h6 {
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  margin-right: 5px;
}

.feed_card span {
  font-size: 12px;
  color: #626679;
  font-weight: 500;
}

.soc_img {
  width: 100%;
  height: 65px;
  border-radius: 6px;
}

.soc_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.new_update h3 {
  font-size: 25px;
  font-weight: 800;
  color: #0140a2;
  margin-bottom: 20px;
}

.news_card {
  background: #e8f1ff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 10%);
  margin: 10px 40px 40px;
  height: 120px;
}

.news_card span {
  color: #e82327;
  font-size: 12px;
  font-weight: 700;
}

.news_card h4 {
  font-size: 16px;
  font-weight: 700;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
}

.news_card p {
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  height: 40px;
  text-align: start;
}

.news_card a {
  font-size: 14px;
  color: #000;
}

.owl-news .owl-nav,
.owl-dots .owl-dots {
  display: none;
}

/* home banenr ends */

/* about section starts */

.tn_about .pos_sti {
  position: sticky !important;
  top: 10%;
}

.history_section .pos_sti {
  position: sticky !important;
  top: 10%;
}

.title_section h2 {
  font-size: 40px;
  font-weight: 800;
  color: #0140a2;
}

.title_section_emis h4 {
  font-size: 25px;
  font-weight: 800;
  color: #0140a2;
}

.about_img img {
  width: 100%;
}

.count_card {
  background: linear-gradient(
    130deg,
    rgba(94, 148, 230, 1) 0%,
    rgba(111, 161, 233, 1) 50%,
    rgba(5, 80, 213, 1) 100%
  );
  height: 100%;
  padding: 15px;
  border-radius: 10px;
}
.count_card h5 {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #fff;
}
.count_con {
  text-align: center;
}

.count_to {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
}
.count_too {
  padding-block: 10px;
  color: #fff;
}
.count_aid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}
.count_stu {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}

.h_line {
  border-right: 1px solid #fff;
}

.count_con h4 {
  font-size: 14px;
}

.count_con {
  font-size: 14px;
  font-weight: 600;
}

.count_icon {
  width: 80px;
  height: 80px;
  background: #fff;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 10px 0px rgba(1, 64, 162, 15%);
}

.count_icon img {
  width: 40px;
}

.count_card h3 {
  font-size: 20px !important;
  font-weight: 800;
  color: #fff;
}

.count_card p {
  color: #fff;
}

/* about section ends */

/* achievement starts */

.achievement_section {
  background: linear-gradient(
    130deg,
    rgba(94, 148, 230, 1) 0%,
    rgba(111, 161, 233, 1) 50%,
    rgba(5, 80, 213, 1) 100%
  );
  padding: 60px 0px;
  position: relative;
}

.achievement_section::before {
  position: absolute;
  content: "";
  background: url(../../public/assets/img/education_tools_bg.png);
  /* background: url(../img/education_tools_bg.png); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.achieve_card {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 10%);
  padding: 15px;
  cursor: pointer;
}

.achieve_img {
  height: 230px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
}

.achieve_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: all 0.5s ease;
}

.achieve_card h3 {
  font-weight: 700;
  font-size: 20px;
  color: #0140a2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
}

.achieve_card p {
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  text-align: start;
  height: 85px;
}

.achieve_card:hover .achieve_img img {
  transform: scale(1.1);
}

/* achievement ends */

/* commitment starts */

.commit_card {
  border-radius: 20px;
  border: 1px solid #999999;
  padding: 20px;
  height: 100%;
  width: 90%;
  margin: 0 auto;
  position: relative;
  box-shadow: 8px 8px 0px 0px rgba(79, 125, 243, 100%);
  transition: all 0.5s ease;
}

.commit_card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 0px 0px 0px rgba(79, 125, 243, 100%);
}

.commit_icon {
  width: 80px;
  height: 80px;
  background: #3676dd;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  margin-top: -60px;
}

.commit_icon img {
  width: 50px;
}

.commit_card h3 {
  font-size: 18px;
  font-weight: 700;
  color: #1a1a1a;
}

/* commitment ends */

/* gallery starts */

.gallery_card {
  position: relative;
  border-radius: 10px;
  background: #000;
  width: 95%;
  margin: 10px auto;
}

.gallery_card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: 0.5s all ease;
}

.gallery_card:hover img {
  opacity: 0.5;
}

.gallery_view {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.5s all ease;
}

.gallery_card:hover .gallery_view {
  opacity: 1;
}

.org_btn .sr_ic {
  background: linear-gradient(
    130deg,
    rgba(94, 148, 230, 1) 0%,
    rgba(111, 161, 233, 1) 50%,
    rgba(5, 80, 213, 1) 100%
  );
  padding: 15px;
  color: #fff;
  border-radius: 100%;
}

.h_300 {
  height: 300px;
}

.h_500 {
  height: 500px;
}

/* gallery ends */

/* thirukural section starts */

.thiru_img img {
  width: 100%;
}

.thiru_content span {
  color: #321011;
  font-size: 20px;
  font-weight: 700;
}

.thiru_content h2 {
  color: #812b20;
  font-size: 40px;
  font-weight: 800;
}

/* thirukural section ends */

/* other links starts */

.other_card {
  border: 1px solid #c8c8c8;
  background: #fff;
  padding: 30px;
  width: 95%;
  margin-inline: auto;
  border-radius: 15px;
}

.other_card:hover {
  background: #e6f0ff;
  transition: all 0.5s ease;
}

.pb_20 {
  padding-bottom: 25px;
}

.other_card img {
  width: 100%;
}

/* other links ends */

/* footer section starts */

.footer_section {
  background: #cee6ff;
  padding: 60px 0px;
}

.footer_section h5 {
  font-size: 24px;
  font-weight: 700;
  color: #000;
  margin-bottom: 20px;
}

.footer_section a {
  font-size: 16px;
  color: #000;
}

.footer_section p {
  text-align: start;
}

.footer_section i {
  font-size: 25px;
  color: #000;
  margin-right: 10px;
}

.soc_ic {
  display: inline-flex;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  background: #0140a2;
  margin: 0px 3px;
}

.soc_ic i {
  color: #fff;
  margin-right: 0;
  font-size: 20px;
}

/* footer section ends */

/* page banner starts */

.page_banner {
  height: 55vh;
  position: relative;
}

.page_banner::before {
  position: absolute;
  background: #00000070;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page_banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img_topcen {
  object-position: top center;
}

.page_content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.page_content h1 {
  font-size: 50px;
  font-weight: 800;
}

.page_content p {
  color: #fff;
}

/* page banner ends */

/* history section starts */

.history_section {
  /* background: url(../../public/assets/img/history_section_background.webp) no-repeat; */
  background-size: cover;
  background-position: center;
  padding: 60px 0px;
  position: relative;
}

.history_section::before {
  position: absolute;
  background: #00000070;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.history_section h2 {
  color: #fff;
}

.history_section p {
  color: #fff;
}

/* history section ends */

/* directorates starts */

.mis-tab .nav-pills .nav-link.active {
  background: #0140a2 !important;
  color: #fff !important;
  font-weight: 800;
}

.mis-tab .nav-item {
  margin: 0px 5px;
}

.mis-tab .nav-pills {
  justify-content: center;
}

.mis-tab .nav-pills .nav-link {
  color: #000 !important;
  font-weight: 500;
  text-align: center;
  font-size: 16px;
}

.director_objectives {
  background: #eff9ff;
  padding: 60px 0px;
}

.objective_list {
  background: #fff;
  border-radius: 10px;
  padding: 30px;
}

.objective_list i {
  color: #0140a2;
}

.objective_list li {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  display: flex;
}

.director_program .ac-it {
  border: none !important;
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 6%);
  margin-bottom: 30px;
}

.director_program .ac-he {
  padding: 35px;
  border-radius: 15px;
  font-size: 20px !important;
}

.director_program .accordion-button:not(.collapsed) {
  background-color: #ffffff00 !important;
  box-shadow: none !important;
  color: #0140a2 !important;
}

.accordion-button {
  padding: 0 !important;
  font-size: 20px !important;
  font-weight: 500;
  color: #000 !important;
}

.accordion-button:focus {
  box-shadow: 0px 0px 0px 0px rgba(8, 15, 52, 0) !important;
}

.director_program .ac-bo {
  padding: 0px 35px 35px;
}

.director_program ol li {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}

.director_program li a {
  color: #e82327 !important;
}

/* directorates ends */

/* scert starts */

.scert_form h4 {
  font-size: 25px;
  font-weight: 700;
  color: #000;
}

.scert_table {
  background: #e9f2ff;
  padding: 60px;
  border-radius: 20px;
}

.scert_table .btn_red {
  margin-top: 20px;
}

.scert_table .btn_red a {
  background-color: #dc3545;
  color: #fff !important;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 15px;
  border-radius: 6px;
}

.scert_table table {
  table-layout: fixed;
}

.scert_table table td {
  padding: 30px 0px !important;
  background: #fff;
  border: 1px solid #cfcfcf !important;
}

.scert_table table th,
.scert_table table td {
  text-align: center !important;
  border: 1px solid #cfcfcf !important;
}

.scert_table td a {
  background-color: #33a672;
  border-radius: 10px;
  padding: 10px 15px;
  color: #fff !important;
  font-weight: 600;
}

label {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #181e4b;
}

.form-control {
  font-weight: 500 !important;
  color: #000 !important;
  font-size: 16px !important;
  padding: 15px !important;
  margin-bottom: 15px !important;
  border-radius: 12px !important;
}

.fms-form label {
  margin-bottom: 0.5rem !important;
  color: #000 !important;
  font-size: 15px !important;
}

.fms-form .form-control {
  font-weight: 400 !important;
  color: #212529 !important;
  font-size: 1rem !important;
  padding: 9px !important;
  /* padding: .375rem .75rem !important; */
  margin-bottom: 10px !important;
  border-radius: 10px !important;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: #dee2e6 !important;
}

.scert_pdf p {
  font-size: 25px !important;
  font-weight: 700;
  margin: 0;
  text-align: start;
}

.scert_pdf h5 {
  font-size: 20px;
  font-weight: 700;
}

.scert_pdf p {
  font-size: 14px;
}

.download_card img {
  width: 30px;
  margin-right: 5px;
}

.download_card h6 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
  color: #000 !important;
}

.download_card {
  padding: 10px;
}

.download_card:hover {
  background: #fff;
  border-radius: 10px;
  transition: all 0.5s ease;
}

/* scert ends */

/* gos starts */
.gos_section h4 {
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

.gos_section .btn_new button {
  background: linear-gradient(
    90deg,
    rgba(0, 182, 240, 1) 0%,
    rgba(63, 162, 83, 1) 100%
  );
  color: #fff;
  border-radius: 100px !important;
  border: none;
  padding: 15px 30px;
  font-weight: 700;
  font-size: 16px;
  height: 50px;
}

#searchform {
  display: block;
  margin: 0 auto;
  width: 100%;
  position: relative;
}

#searchform span {
  font-size: 15px;
  font-family: "f1";
}

#search-bar {
  display: block;
  width: 100%;
  font-size: 13px !important;
  padding: 10px !important;
  height: 50px;
}

.bg_none {
  background: none;
}

.btn-sea {
  position: absolute;
  top: 14px;
  right: 14px;
  background: none;
  border: none;
}

.btn_outli {
  border: 1px solid #d0d5dd;
  padding: 10px;
  width: 120px;
  border-radius: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_blue a {
  background-color: #2468da;
  color: #fff !important;
  font-weight: 700;
  font-size: 18px;
  padding: 15px 20px;
  border-radius: 10px;
}

.btn_red a {
  background-color: #dc3545;
  color: #fff !important;
  font-weight: 700;
  font-size: 18px;
  padding: 15px 20px;
  border-radius: 10px;
}

.btn_blue_tab a {
  background-color: #2468da;
  color: #fff !important;
  font-weight: 600;
  font-size: 14px;
  padding: 8px 15px;
  border-radius: 10px;
}

.a_blue {
  background-color: #2468da;
  color: #fff !important;
  font-weight: 700;
  font-size: 18px;
  padding: 15px 20px;
  border-radius: 10px;
  display: inline-block;
  width: 200px;
  border: none;
}

.a_red {
  background-color: #dc3545;
  color: #fff !important;
  font-weight: 700;
  font-size: 18px;
  padding: 15px 20px;
  border-radius: 10px;
  display: inline-block;
  width: 200px;
  border: none;
}

.btn_ic {
  background-color: #2468da;
  padding: 15px 20px;
  border-radius: 50%;
  display: inline-block;
  rotate: 320deg;
}

.btn_ic a {
  color: #fff !important;
  font-weight: 700;
  font-size: 18px;
}

.fil_ic {
  font-size: 25px;
  margin-right: 5px;
}

.table_section {
  overflow-x: auto;
  scrollbar-width: thin;
}

.table_section table {
  width: 100%;
  overflow: scroll;
  border: 1px solid #eaecf0;
}

.table_section table th {
  background: #0140a2;
  color: #fff;
  padding: 10px;
  vertical-align: middle;
}

.table_section table td {
  padding: 20px 10px 20px;
  font-size: 15px;
  color: #000 !important;
  border-bottom: 1px solid #eaecf0;
}

.table_section a {
  color: #fff !important;
}

/* gos ends */

/* right education starts */

.rte_provide {
  border: 1px solid #0140a2;
  padding: 35px;
  border-radius: 20px;
}

.rte_provide h4 {
  font-size: 25px;
  font-weight: 700;
  color: #181e4b;
  margin-bottom: 15px;
}

.rte_provide li {
  display: flex;
  margin-bottom: 15px;
}

.arr_ic {
  color: #0140a2;
  font-size: 25px;
  margin-right: 5px;
  margin-top: -5px;
}

.right_rte {
  background: #eff9ff;
  border-radius: 20px;
  padding: 20px;
}

.right_rte h5 {
  font-size: 20px;
  font-weight: 700;
}

.right_rte p {
  font-size: 14px;
}

/* right education ends */

/* mission section starts */

.time_line .title_section p {
  text-align: center;
}

.mission_section {
  background: linear-gradient(
    130deg,
    rgba(94, 148, 230, 1) 0%,
    rgba(111, 161, 233, 1) 50%,
    rgba(5, 80, 213, 1) 100%
  );
  padding: 60px 0px;
  position: relative;
}

.mission_section::before {
  position: absolute;
  content: "";
  background: url(../../public/assets/img/education_tools_bg.png);
  /* background: url(../img/education_tools_bg.png); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
}

.mission_card {
  background: #fff;
  border-radius: 0px 20px 0px 20px;
  padding: 20px 25px;
}

.mission_card img {
  width: 25px;
  margin-right: 10px;
}

.mission_card h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.miss_le {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.mis_1 {
  width: 290px;
  height: 250px;
}

.mis_2 {
  width: 220px;
  height: 180px;
}

.mis_3 {
  width: 295px;
  height: 530px;
}

.mission_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mission_left {
  position: relative;
}

.mission_left img {
  height: 600px;
  border-radius: 0px 20px 20px 0px;
}

.mission_leftcon {
  position: absolute;
  top: 50px;
  left: 275px;
  width: 400px;
  padding: 20px;
  border-radius: 20px;
  background: #0140a280;
  z-index: 1;
}

.mission_leftcon p,
.mission_righcon p {
  color: #fff;
}

.mission_right {
  position: relative;
  margin-top: 150px;
}

.mission_right img {
  height: 300px;
  border-radius: 20px 0px 0px 20px;
}

.mission_righcon {
  position: absolute;
  top: 200px;
  right: 195px;
  width: 400px;
  padding: 20px;
  border-radius: 20px;
  background: #0140a280;
}

.yellow_cube {
  position: absolute;
  content: "";
  width: 180px;
  height: 180px;
  top: -62px;
  left: -144px;
  background: #ffbb38;
  z-index: -1;
  rotate: 56deg;
}

/* mision section ends */

/* know emis starts */

.know_emis img {
  width: 100%;
  border-radius: 20px;
}

.know_emis input::-webkit-outer-spin-button,
.know_emis input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.know_login {
  padding: 15%;
}

.know_login h2 {
  font-size: 50px;
  font-weight: 800;
  color: #0140a2;
}

.captcha_sec {
  background: #eff9ff;
  font-weight: 500 !important;
  color: #000 !important;
  font-size: 16px !important;
  margin-bottom: 15px !important;
  border-radius: 12px !important;
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.captcha_sec button {
  width: 50px;
  height: 50px;
}

.captcha_sec .btn:focus {
  box-shadow: none !important;
}

.captcha_sec h5 {
  margin-bottom: 0;
}

.btn_login input {
  width: 100%;
  background: #0140a2;
  color: #fff;
  border: none;
  padding: 10px;
  height: 50px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 18px;
}

.know_login h6 {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #777777;
  margin-top: 15px;
}

.know_login h6 a {
  color: #000 !important;
  font-weight: 700;
}

.know_login input::placeholder {
  color: #c5c5c5;
}

/* know emis ends */

/* magazine starts */

.sub_detail {
  background: #eff9ff;
  padding: 60px 0px;
}

.sub_detail h3 {
  font-size: 25px;
  font-weight: 700;
  color: #000;
}

.sub_card {
  background: #fff;
  border-radius: 25px;
  padding: 30px;
}

.sub_card li {
  margin-bottom: 15px;
  font-weight: 500;
}

.maga_card {
  width: 90%;
  margin: 0 auto;
  transition: all 0.5s ease;
}

.maga_img {
  overflow: hidden;
  margin-bottom: 15px;
}

.maga_img img {
  width: 100%;
  transition: all 0.5s ease;
}

.maga_card:hover .maga_img img {
  transform: scale(1.1);
}

.maga_card h4 {
  font-size: 18px;
  font-weight: 700;
  color: #000;
}

.maga_card h6 {
  color: #000;
}

.maga_card h6 span {
  font-weight: 700;
  color: #000;
}

.select_sub h5 {
  font-size: 20px;
  font-weight: 700;
}

.select_sub p {
  font-size: 14px;
}

.select_sub a {
  color: #000;
}

.select_sub i {
  font-size: 25px;
  margin-right: 10px;
}

.reach_section {
  background: #eff9ff;
  padding: 40px;
  border-radius: 20px;
}

.reach_section h2 {
  font-size: 30px;
  font-weight: 800;
  color: #0140a2;
}

.magazine_item {
  padding: 0px 0px 30px;
}

.magazine_item h3 {
  font-size: 25px;
  font-weight: 700;
}

.maga_count img {
  width: 30px;
  margin-right: 10px;
}

.maga_count p {
  margin: 0;
  font-size: 20px;
}

.maga_ban_img img {
  width: 100%;
}

.magazine_scroll .carousel-indicators [data-bs-target] {
  background-color: #000 !important;
}

/* magazine ends */

/* magazine payment starts */

#heading {
  color: #0140a2;
  font-weight: 800;
  font-size: 40px;
}

#msform {
  text-align: center;
  position: relative;
  margin-top: 20px;
}

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
  position: relative;
}

.form-card {
  text-align: left;
}

#msform fieldset:not(:first-of-type) {
  display: none;
}

#msform input,
#msform textarea {
  padding: 8px 15px 8px 15px;
  border-radius: 0px;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  font-weight: 500;
  color: #2c3e50;
  font-size: 16px;
}

#msform input:focus,
#msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #673ab7;
  outline-width: 0;
}

#msform .action-button {
  width: 100px;
  background: #0140a2;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 10px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 0px 10px 5px;
  float: right;
}

#msform .action-button:hover,
#msform .action-button:focus {
  background-color: #00307d;
}

#msform .action-button-previous {
  width: 100px;
  background: #616161;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 10px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px 10px 0px;
  float: right;
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
  background-color: #000000;
}

.card {
  z-index: 0;
  border: none;
  position: relative;
}

.fs-title {
  font-size: 25px;
  color: #0140a2;
  margin-bottom: 15px;
  font-weight: 700;
  text-align: left;
}

.purple-text {
  color: #673ab7;
  font-weight: normal;
}

.steps {
  font-size: 20px;
  color: #000;
  margin-bottom: 10px;
  font-weight: 700;
  text-align: right;
}

.fieldlabels {
  color: gray;
  text-align: left;
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;
}

#progressbar .active {
  color: #0140a2;
}

#progressbar li {
  list-style-type: none;
  font-size: 15px;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400;
}

#progressbar small {
  font-size: 14px;
  color: #000;
}

#progressbar #account:before {
  font-family: FontAwesome;
  content: "\f13e";
}

#progressbar #personal:before {
  font-family: FontAwesome;
  content: "\f007";
}

#progressbar #payment:before {
  font-family: FontAwesome;
  content: "\f030";
}

#progressbar #confirm:before {
  font-family: FontAwesome;
  content: "\f00c";
}

#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
}

#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: #0140a2;
}

.progress {
  height: 20px;
}

.progress-bar {
  background-color: #673ab7;
}

.fit-image {
  width: 100%;
  object-fit: cover;
}

.order_book {
  margin-right: 15px;
}

.order_book img {
  height: 200px;
  border-radius: 10px;
}

.order_bocon h3 {
  font-size: 16px;
  font-weight: 700;
}

.order_bocon p {
  font-size: 14px;
  margin-bottom: 10px;
}

.order_bocon h6 {
  font-size: 16px;
  font-weight: 700;
  color: #0140a2;
}

.order_magazine h4 {
  font-size: 16px;
  font-weight: 600;
}

.payment_am {
  border-top: 1px solid #000;
}

.payment_am h4 {
  font-size: 20px;
  font-weight: 700;
}

.bold_txt {
  font-weight: 700;
}

/* magazine payment ends */

.dropdown-toggle {
  color: #fff !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  border: none !important;
}

.btn:first-child:active {
  border: none !important;
}

/* timeline starts */
/* 
.timeline ul li {
  list-style-type: none;
  position: relative;
  width: 3px;
  margin: 0 auto;
  padding-top: 50px;
  background: #000;
}

.timeline ul li::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: inherit;
}

.timeline ul li div {
  position: relative;
  bottom: 0;
  width: 400px;
  padding: 30px;
  background: linear-gradient(
    130deg,
    rgba(94, 148, 230, 1) 0%,
    rgba(111, 161, 233, 1) 50%,
    rgba(5, 80, 213, 1) 100%
  );
}

.timeline ul li:nth-child(odd) div {
  left: 45px;
}

.timeline ul li:nth-child(even) div {
  left: -439px;
}

time {
  display: block;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 8px;
} */

/* EFFECTS
–––––––––––––––––––––––––––––––––––––––––––––––––– */

/* .timeline ul li::after {
  transition: background 0.5s ease-in-out;
}

.timeline ul li.in-view::after {
  background: #fae100;
} */

/* .timeline ul li div {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.timeline ul li:nth-child(odd) div {
  transform: translate3d(200px, 0, 0);
} */

/* .timeline ul li:nth-child(even) div {
  transform: translate3d(-200px, 0, 0);
}

.timeline ul li.in-view div {
  transform: none;
  visibility: visible;
  opacity: 1;
  border-radius: 20px;
  color: #fff;
  font-weight: 700;
  font-size: 25px;
} */

/* timeline ends */

.translate_btn {
  border: 3px solid #379683;
  border-radius: 7px;
  color: #fff;
  background-color: #379683;
  transition-duration: 0.4s;
  text-align: center !important;
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
  padding: 0.25rem !important;
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
  cursor: pointer;
}

.table_input {
  border: none;
  background: transparent;
  width: 100%;
  text-align: center;
}

.table_input:focus {
  outline: none;
}

.accordion-item {
  color: var(--bs-accordion-color) !important;
  background-color: #ffffff !important;
  border: 1px solid #ffffff !important;
}

/* FMS Program Starts */

.fms_card {
  background-color: #fff;
  border-radius: 30px;
  padding: 50px;
  box-shadow: 0px 12px 24px 0px rgba(64, 103, 178, 20%);
}

.fms_card h2 {
  font-weight: 800;
  font-size: 40px;
  color: #0140a2;
}

.fms_card h6 {
  font-size: 25px;
  font-weight: 400;
}

.fms_login h6 {
  font-size: 16px;
  line-height: 30px;
}

.fms_login h6 span {
  color: #2468da;
  font-weight: 600;
}

.flex-col {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.fms_login h4 {
  font-size: 25px;
  font-weight: 700;
}

.fms_login p {
  font-size: 24px;
  color: #757575;
}

/* FMS Program ends */

/* MAGAZINE PAYMENT START   */
.quantity-selector {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quantity-selector button {
  width: 20%;
  background: #0140a2;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 10px;
  cursor: pointer;
  padding: 7px 5px;
  margin: 10px 0px 10px 5px;
  float: right;
}

.quantity-selector span {
  margin: 0 15px;
  font-size: 1.2rem;
  font-weight: 500;
}

/* MAGAZINE PAYMENT END   */

.err-input {
  position: absolute;
  bottom: -7px;
  right: 0;
  font-size: 12px !important;
  font-family: "f2";
  color: red !important;
}

.embla {
  position: relative;
  overflow: hidden;
  max-width: 90%;
  margin: 100px auto;
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  display: flex;
}

.embla__slide {
  flex: 0 0 25%;
  /* Default 4 slides per view */
  padding: 20px;
  background-color: #ddd;
  text-align: center;
  font-size: 20px;
  border-radius: 10px;
  margin: 0px 20px;
}

/* Medium screens: Show 2 slides */
@media (max-width: 1024px) {
  .embla__slide {
    flex: 0 0 50%;
  }
}

/* Small screens: Show 1 slide */
@media (max-width: 768px) {
  .embla__slide {
    flex: 0 0 100%;
  }
}

/* Navigation buttons */
.embla__prev,
.embla__next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 24px;
}

.embla__prev {
  left: 10px;
}

.embla__next {
  right: 10px;
}

.bott_c {
  background-color: #0140a2;
  padding: 15px;
  display: flex;
  padding-top: 20px;
  justify-content: center;
  align-items: center;
}

/* blog starts */

.blog_card {
  background: #f1f7ff;
  border-radius: 20px;
  border: 1px solid #dddddd;
  padding: 15px;
  width: 95%;
  margin-inline: auto;
}

.blog_card .btn_new a {
  padding: 15px;
}

.blog_thumb_img {
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 20px;
  height: 220px;
  position: relative;
}

.blog_thumb_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog_thdate {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 1;
  background: #fff;
  border-radius: 5px;
  padding: 5px 10px;
}

.blog_thdate span {
  color: #3373dc;
  font-size: 14px;
  font-weight: 600;
}

.blog_card h3 {
  font-size: 20px;
  font-weight: 700;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  line-height: 30px;
}

.blog_card p {
  font-size: 16px;
  font-weight: 500;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  line-height: 30px;
  text-align: start;
}

.read_time span {
  color: #3978dd;
  font-weight: 600;
  text-align: end;
}

.read_time {
  color: #3978dd;
  font-weight: 600;
  text-align: end;
}

.blog_dettit h2 {
  font-size: 40px;
  font-weight: 800;
  color: #0140a2;
  margin-bottom: 20px;
}

.blog_shsocial {
  display: flex;
  flex-direction: column;
  gap: 13px;
  align-items: end;
}

.blog_shsocial span {
  font-weight: 500;
}

.blog_detdes h3 {
  font-weight: 700;
  font-size: 25px;
}

.blog_detdes ul {
  list-style: disc !important;
  padding-left: 2rem !important;
  margin-top: 15px;
}

.blog_detdes li {
  color: #000;
  line-height: 30px;
  font-weight: 500;
  font-size: 16px;
}

.blog_detdes img {
  width: 100%;
  border-radius: 20px;
}

.blog_bg_img {
  height: 500px;
}

.blog_bg_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* blog ends */

/* quick circle starts */

.cir_btn {
  background: #0140a2;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.menu_cr {
  position: fixed;
  right: 80px;
  bottom: 55px;
  z-index: 99;
}

.menu_at {
  width: 10px;
  height: 10px;
  border-radius: 70px;
  position: absolute;
  overflow: hidden;
  cursor: pointer;
  padding: 20px;
}
.menu_at img {
  width: 20px;
}
.md-36 {
  font-size: 20px;
  color: #03a9f4;
}

.menu_at {
  background: #eceff1;
  font-size: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.menu_at:not(:first-child) {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  z-index: -2;

  -webkit-transition: all 0.6s cubic-bezier(0.87, -0.41, 0.19, 1.44);
  transition: all 0.6s cubic-bezier(0.87, -0.41, 0.19, 1.44);
}

.menu_at:nth-child(2) {
  top: 0px;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.menu_at:nth-child(3) {
  top: 0px;
  left: 0px;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.menu_at:nth-child(4) {
  left: 0px;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.menu_at:nth-child(5) {
  top: 0px;
  left: 0px;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.menu_at:nth-child(6) {
  top: 0px;
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.menu_at:nth-child(7) {
  top: 0px;
  left: 0px;
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.menu_at:nth-child(8) {
  left: 0px;
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

.menu_at:nth-child(9) {
  top: 0px;
  left: 0px;
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

input#toggle:checked ~ #show-menu .menu_at:nth-child(2) {
  top: -60px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input#toggle:checked ~ #show-menu .menu_at:nth-child(3) {
  top: -45px;
  left: 45px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input#toggle:checked ~ #show-menu .menu_at:nth-child(4) {
  left: 60px;
  top: 0px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input#toggle:checked ~ #show-menu .menu_at:nth-child(5) {
  top: 45px;
  left: 45px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input#toggle:checked ~ #show-menu .menu_at:nth-child(6) {
  top: 60px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input#toggle:checked ~ #show-menu .menu_at:nth-child(7) {
  top: 45px;
  left: -45px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input#toggle:checked ~ #show-menu .menu_at:nth-child(8) {
  left: -60px;
  top: 0px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input#toggle:checked ~ #show-menu .menu_at:nth-child(9) {
  top: -45px;
  left: -45px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.menuBtn,
.closeBtn {
  position: absolute;
  transition: all 0.3s ease;
  color: #fff;
}

.closeBtn {
  transform: translateY(50px);
  opacity: 0;
}

input#toggle:checked ~ #show-menu .menu_at .menuBtn {
  transform: translateY(-50px);
  opacity: 0;
}

input#toggle:checked ~ #show-menu .menu_at .closeBtn {
  transform: translateY(0px);
  opacity: 1;
}

/* quick circle ends */

.vertical-timeline::before {
  /* this is the vertical line */
  background: black !important;
}

/* thirukural section starts */

.th_section {
  background: url(../../public/assets/img/thirukural_bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 60px;
}
.th_flex {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-items: center;
}
.thi_img img {
  width: 100%;
}
.th_cont{
  display: flex;
  flex-direction: column;
  gap: 20px;

}
.th_cont span{
  font-size: 20px;
  font-weight: 700;
  color: #321011;
}
.th_cont h3{
  font-size: 35px;
  font-weight: 800;
  color: #812B20;
}
.th_cont p{
  font-size: 16px;
  font-weight: 500;
  color: #321011;
  text-align: start;
}
/* thirukural section ends */
